<template>
    <div style=" width:1230px;margin:0 auto;background:#f8f8f9;" >
        
        <div style="width:100%" >
            <iframe :srcdoc="aliHtml"
                    frameborder="no"
                    border="0"
                    marginwidth="0"
                    marginheight="0"
                    scrolling="no"
                    width ="1230"
                    height="800"   
                    style="overflow:hidden"     
            >
            </iframe>
        </div>
        <div  style="line-height:35px;height:35px;border-left:2px solid #409eff;text-align:left;padding-left:8px;background:#f0f8ff">
            <i class="iconfont icon-shangyiye" style="color:#409eff;margin-right:3px;"></i>
            <el-link :underline="false" @click="modPay">返回到购物车</el-link>
        </div> 
        <!-- <div id="qrcode"  style="width:250px; height:250px;margin-top:30px; text-align:center;vertical-align:center;margin-left:50px;"> </div> -->

    </div>
</template>
<script>
 
export default {
    name:'alipay',
    data () {
        return {
            aliHtml:'',
            ordno: '' ,//订单编码
            totalAmt:'', //订单金额
        }
    },
    created () {
         let idno =this.$route.query.p_idno
         let amt =this.$route.query.p_amt
         if (idno){
            this.setPayno(idno,amt ) 
         }
    },
    methods: {
        modPay(){
            this.$emit('child-pay')
        },
        //支付宝跳转模式 get方式 params ,post 方式 data
        aliPay(){
            var that =this
            this.$axios({
                method: 'get',
                url:this.$store.state.baseUrl+'auth/login/createQR',
                params: {trade_no:this.ordno,total_amount:this.totalAmt,subject:this.ordno,body:'Kreco订单#'+this.ordno+'支付'},
            }).then(res=>{
                this.aliHtml=res.data.content
                if("WebSocket" in window){
                    var ws =new WebSocket(this.$store.state.wsUrl+this.$cookies.get('v_telno' ))
                    ws.onmessage =function(evt){
                        var receive_msg =evt.data
                        console.log('socket:' +receive_msg)
                        if (receive_msg=='true'){
                            setTimeout(()=>{
                                let routeUrl = that.$router.resolve({
                                    path: "/member/basic_info",
                                    query: {lang:that.$store.state.lang,pid:'/member/poord' },
                                });
                                window.open(routeUrl.href, '_self');  
                            },1000)
                        }
                        ws.close()
                    }
                }else{
                    this.$alert('你的浏览器不支持websocket', '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'warning'
                    });
                }
            })
        },
        // 传订单号、金额
        setPayno(ordno,amt ){
            this.ordno=ordno
            this.totalAmt = Number(amt).toFixed(2) //保留2位小数
            this.aliPay()
        }
    }
}
</script>
<style scoped>
    .divcolor{
        background-color: #5cadff;
        font-size:36px;
        margin-top:10px ;
    }
</style>